







































import Vue from "vue";

export default Vue.extend({
  name: "Login",
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
    };
  },
  methods: {
    login() {
      this.axios.post('auth/login', {
        email: this.email,
        password: this.password,
      }, { withCredentials: true })
        .then(() => {
          this.axios.get('auth/user', { withCredentials: true })
            .then((res) => {
              const info = res.data;
              this.$store.state.authInfo.id = info.id;
              this.$store.state.authInfo.email = info.email;
              this.$store.state.authInfo.preName = info.preName;
              this.$store.state.authInfo.lastName = info.lastName;
              this.$store.state.authInfo.permissions = info.permissions;
              this.$store.state.authInfo.totpEnabled = info.totpEnabled;
              this.$store.state.loggedIn = true;
              this.redirect();
            });
        })
      .catch((err) => {
            console.log(err.response.status, err.response.data);
            
            if (err.response.status === 401 && err.response.data === 'MISSING_TWOFA_TOKEN') {
              this.$router.push({
                name: 'Totp',
                params: {
                  email: this.email,
                  password: this.password,
                  redirect: this.$router.currentRoute.query.redirect?.toString(),
                },
              });
            }
          });
    },
    redirect() {
      const redirect = this.$router.currentRoute.query.redirect?.toString();
      if (!redirect) return this.$router.push('/');
      switch (redirect) {
        case 'back':
          window.history.back();
          break;
        case 'close':
          window.close();
          break;
        default:
          this.$router.push(redirect);
          break;
      }
    },
  },
});
